

<template>
  <div v-if="messages">
    <div v-for="(oneMessage, index) in messages" :key="index" @click="removeMessage(index)">
      <v-alert
        v-if="oneMessage"
        :type="oneMessage.type.toLowerCase()"
        border="left"
        text
        outlined
        prominent
        dense
        dismissible
        max-height="60"
        :style="{'background-color': 'white !important', 'padding': '7px 15px'}"
      >
        <h4 v-if="oneMessage.title">{{oneMessage.title}}</h4>
        <div v-html="oneMessage.text"></div>
      </v-alert>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'globalMessages',
    props: ['messages'],
    methods: {
      removeMessage: function (index) {
        let messages = this.$store.getters['globalMessages/list'];
        if (messages && messages.length > 0) {
          for (let i = messages.length - 1; i >= 0; i--) {
            if (i === index) {
              messages.splice(i, 1);
              break;
            }
          }

          this.$store.dispatch('globalMessages/set', messages);
        }
      }
    }
  }
</script>
